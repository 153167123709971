import axios from 'axios';
import { CURRENT_EVENT, NEXT_EVENT } from '../constants';
import { token } from '../stores/token';
// const baseUrl = 'http://localhost:3000/api/';
const baseUrl = 'https://tgex-backend.herokuapp.com/api/';
const baseAPI = axios.create({
    baseURL: baseUrl,
});
const eventAPI = axios.create({
    baseURL: `${baseUrl}/event/${CURRENT_EVENT}`,
});
const nextEventAPI = axios.create({
    baseURL: `${baseUrl}/event/${NEXT_EVENT}`,
});
token.subscribe((token) => {
    eventAPI.defaults.headers.common.authorization = `Bearer ${token}`;
    nextEventAPI.defaults.headers.common.authorization = `Bearer ${token}`;
    baseAPI.defaults.headers.common.authorization = `Bearer ${token}`;
});
export default eventAPI;
const trycatch = async (func) => {
    try {
        const response = await func();
        return response.data;
    }
    catch (error) {
        if (error.message === 'Network Error') {
            return Promise.reject({
                error: 'Could not connect to server. Please try again later',
            });
        }
        if (error.response.status === 401) {
            token.set(null);
        }
        if (error.response.status === 304) {
            error.response.data = { error: 'Nothing changed' };
        }
        return Promise.reject(error.response.data);
    }
};
export const remove = async (url) => {
    return trycatch(() => eventAPI.delete(url));
};
export const get = async (url, config = {}) => {
    return trycatch(() => eventAPI.get(url, config));
};
export const post = async (url, data, config = {}) => {
    return trycatch(() => eventAPI.post(url, data, config));
};
export const update = async (url, data) => {
    return trycatch(() => eventAPI.put(url, data));
};
export const baseGet = async (url, config = {}) => {
    return trycatch(() => baseAPI.get(url, config));
};
export const basePost = async (url, data, config = {}) => {
    return trycatch(() => baseAPI.post(url, data, config));
};
export const baseUpdate = async (url, data) => {
    return trycatch(() => baseAPI.put(url, data));
};
export const nextRemove = async (url) => {
    return trycatch(() => nextEventAPI.delete(url));
};
export const nextGet = async (url, config = {}) => {
    return trycatch(() => nextEventAPI.get(url, config));
};
export const nextPost = async (url, data, config = {}) => {
    return trycatch(() => nextEventAPI.post(url, data, config));
};
export const nextUpdate = async (url, data) => {
    return trycatch(() => nextEventAPI.put(url, data));
};
