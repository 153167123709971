<script lang="ts">
	import {onMount} from 'svelte';
	import {
		getApplications,
		acceptApplication as apiAcceptApplication,
		rejectApplication as apiRejectApplication,
	} from '../api/crewapplication';
	import {CrewType} from '../constants';
	import type {CrewApplicationWithUser} from '../global';

	let applications = [];
	let showReviewed = true;

	onMount(async () => {
		fetchApplications();
	});

	const fetchApplications = async () => {
		applications = await getApplications();
		applications.forEach((app) => {
			app.acceptedCrewType = app.wantedCrew;
		});

		applications.reverse();
	};

	const genderMap = {
		m: 'Gutt / Mann',
		f: 'Jente / Kvinne',
		o: 'Annet',
	};

	const crewTypeMap = {
		[CrewType.Game]: 'Game',
		[CrewType.Entertainment]: 'Entertainment',
		[CrewType.Tech]: 'Tech',
		[CrewType.Kiosk]: 'Kiosk',
		[CrewType.Security]: 'Security',
	};
	const crewTypeClass = {
		[CrewType.Admin]: 'crew crew-admin',
		[CrewType.Game]: 'crew crew-game',
		[CrewType.Entertainment]: 'crew crew-entertainment',
		[CrewType.Tech]: 'crew crew-tech',
		[CrewType.Kiosk]: 'crew crew-kiosk',
		[CrewType.Security]: 'crew crew-security',
	};

	function getAge(dateString: string | number | Date) {
		var today = new Date();
		var birthDate = new Date(dateString);
		var age = today.getFullYear() - birthDate.getFullYear();
		var m = today.getMonth() - birthDate.getMonth();
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--;
		}
		return age;
	}

	const acceptApplication = async (application: CrewApplicationWithUser) => {
		application.isReviewed = true;
		await apiAcceptApplication(
			application.id,
			application.acceptedCrewType,
			application.reviewReason
		);

		await fetchApplications();
	};
	const rejectApplication = async (application: CrewApplicationWithUser) => {
		application.isReviewed = true;
		await apiRejectApplication(
			application.id,
			application.reviewReason,
			application.blockFromCrew || false
		);

		await fetchApplications();
	};

	const getFiltered = (
		applications: CrewApplicationWithUser[],
		showReviewed
	): CrewApplicationWithUser[] => {
		if (!showReviewed) return applications.filter((app) => !app.isReviewed);

		return applications;
	};
</script>

<div class="page-content">
	<br />
	<label
		><input type="checkbox" bind:checked={showReviewed} /> Vis besvarte søknader</label
	>
	{#if !showReviewed && getFiltered(applications, showReviewed).length === 0}
		<p>Det er ingen ubesvarte søknader for øyeblikket</p>
	{/if}
	{#each getFiltered(applications, showReviewed) as application}
		<div
			class="application"
			class:accepted={application.isReviewed && application.isApproved}
			class:rejected={application.isReviewed && !application.isApproved}
		>
			<h3><b>Søknad:</b></h3>
			<div class="application-header">
				<div>
					<p>
						(<b>{application.user.gamertag}</b>)
						{application.user.firstName}
						{application.user.lastName}
					</p>
					<p>Kjønn: {genderMap[application.user.gender]}</p>
					<p>
						Født: {new Date(
							application.user.birthdate
						).toLocaleDateString('nob')} ({getAge(
							application.user.birthdate
						)} år)
					</p>
				</div>
				<div>
					<p><b>Kontaktinfo:</b></p>
					<p>Telefon: {application.user.tel}</p>
					<p>Epost: {application.user.email}</p>
				</div>
			</div>
			<p class="wanted-crew">
				Ønsket crew: <br /><span
					class={crewTypeClass[application.wantedCrew]}
				>
					1. {crewTypeMap[application.wantedCrew]}
				</span>
				{#if application.wantedCrew2}
					<span class={crewTypeClass[application.wantedCrew2]}>
						2. {crewTypeMap[application.wantedCrew2]}
					</span>
				{/if}
				{#if application.wantedCrew3}
					<span class={crewTypeClass[application.wantedCrew3]}>
						3. {crewTypeMap[application.wantedCrew3]}
					</span>
				{/if}
			</p>
			<p>
				<b>Tidligere LAN-, eller annen relevant erfaring:</b><br />
				{application.previousExperience}
			</p>
			<p><b>Jeg er dyktig på:</b> <br /> {application.goodAt}</p>
			<p>
				<b>Er det noen spesielle hensyn vi må ta?</b> <br />
				{application.specialConsiderations}
			</p>
			{#if !application.isReviewed}
				Godkjenn: <select bind:value={application.acceptedCrewType}>
					<option value={CrewType.Security}>
						{crewTypeMap[CrewType.Security]}
					</option>
					<option value={CrewType.Kiosk}>
						{crewTypeMap[CrewType.Kiosk]}
					</option>
					<option value={CrewType.Tech}>
						{crewTypeMap[CrewType.Tech]}
					</option>
					<option value={CrewType.Entertainment}>
						{crewTypeMap[CrewType.Entertainment]}
					</option>
					<option value={CrewType.Game}>
						{crewTypeMap[CrewType.Game]}
					</option>
				</select>
				<button on:click={() => acceptApplication(application)}
					>Godkjenn</button
				>
				<br />
				Avslå:
				<input
					type="text"
					name="reason"
					bind:value={application.reviewReason}
					placeholder="Grunn for Avslag"
				/>
				<button on:click={() => rejectApplication(application)}
					>Avvis</button
				>
			{:else if !application.isApproved}
				<p>
					<i>
						<b>Grunnlag for avslag:</b>
						{application.reviewReason}
					</i>
				</p>
			{/if}
		</div>
	{/each}
</div>

<style>
	.application {
		margin-bottom: 40px;
		border-left: solid 5px #aaa;
		padding-left: 10px;
		border-radius: 2px;
	}
	.application.accepted {
		border-color: #6ff838;
	}
	.application.rejected {
		border-color: #ff3939;
	}
	.application h3 {
		margin-bottom: 0;
	}

	.application-header {
		display: flex;
		flex-wrap: wrap;
	}
	.application-header div {
		margin-right: 20px;
		margin-bottom: 10px;
	}

	.application-header p {
		margin: 0;
	}

	.wanted-crew {
		margin-top: 0;
	}

	.crew {
		color: black;
		padding: 2px 10px;
		border-radius: 5px;
		display: inline-block;
	}
	.crew-admin {
		background-color: #6ff838;
	}
	.crew-game {
		background-color: #2d73f7;
		color: white;
	}
	.crew-entertainment {
		background-color: #6dddff;
	}
	.crew-kiosk {
		background-color: #ca00ca;
	}
	.crew-security {
		background-color: #ffff00;
	}
	.crew-tech {
		background-color: #ff0000;
	}

	select,
	input[type='text'] {
		padding: 5px 10px;
		border-radius: 5px;
	}
	button {
		padding: 5px 10px;
		border-radius: 5px;
	}
</style>
