import { nextGet, nextPost } from './api';
export const getApplications = async () => {
    return nextGet(`/crew/applications`);
};
export const postApplication = async (applicationData) => {
    return nextPost('/crew/application', applicationData);
};
export const acceptApplication = async (applicationId, acceptedCrewType, reviewReason) => {
    return nextPost(`/crew/application/${applicationId}/accept`, {
        acceptedCrewType,
        reviewReason,
    });
};
export const rejectApplication = async (applicationId, reviewReason, blockFromCrew) => {
    return nextPost(`/crew/application/${applicationId}/reject`, {
        reviewReason,
        blockFromCrew,
    });
};
